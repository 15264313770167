const staging = false;
const apiBase =
  process.env.NODE_ENV === "production"
    ? window.location.hostname
    : "http://localhost:8080";

module.exports = {
  // ports
  serverPort: 8080,
  //This app url
  appURL: apiBase,
  groupEntityTypeId: staging
    ? "6571f6097e4234a569055893"
    : "66ed524d2cda342f5c008142",
  groupMemberEntityTypeId: staging
    ? "6571f6047e4234a569055892"
    : "66ed522ee9d9b4302302e4b2",
  resourceName: "calendar",
  defaultRole: "manager",
};
